import React, {useEffect} from 'react'
import Img from 'gatsby-image'
import { MdLanguage } from 'react-icons/md'
import styled from 'styled-components'
import {useStaticQuery, graphql, Link} from 'gatsby'
import {Button} from "./Button"
import { BiWorld } from "react-icons/bi"
import Aos from 'aos'
import 'aos/dist/aos.css'
import { FormattedMessage } from 'react-intl'


const ProjectsSection = ({ heading }) => {

    useEffect( () => {
        Aos.init({})
      }, [])

const data = useStaticQuery(graphql`
 query MyQuery {
    allProjectsJson {
      edges {
        node {
          alt
          button
          name
          img {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`)

function getProjects(data) {
    const projectsArray = []
    data.allProjectsJson.edges.forEach((item, index) => {
        projectsArray.push(
          <ProjectCard key={index} >
            <ProjectImg 
              alt = {item.node.alt}
              fluid = {item.node.img.childImageSharp.fluid}
            />

            <ProjectInfo>
                <TextWrap>
                    {/* <BiLocation />
                    <ProjectTitle>{item.node.name}</ProjectTitle> */}
                </TextWrap>
            </ProjectInfo>
           </ProjectCard>
        )
    })

    return projectsArray
}



    return (
        <ProjectsContainer>
            <ProjectsHeader data-aos="fade-up"
              data-aos-delay="50"
              data-aos-duration="1000"> <FormattedMessage id="ProjectsHeader" /> </ProjectsHeader>
            <ProjectsWrapper data-aos="fade-up"
          data-aos-delay="150"
          data-aos-duration="1000">{getProjects(data)}</ProjectsWrapper>
            <Button data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="1000"primary='true' round='true' to='//www.facebook.com/etihadshipping' target="_blank"> <FormattedMessage id="ProjectsBtn" /> </Button>
        </ProjectsContainer>
    )
}

export default ProjectsSection

const ProjectsContainer = styled.div`
    /* min-height: 100vh; */
    height: 100%;
    width: 100%;
    padding: 5rem calc((100vw - 1300px) / 2);
    background-color: #dbe2e7;
    color: #bd0b27;
    text-align: center;
`

const ProjectsHeader = styled.h1`
    font-size: clamp(1.4rem, 3.8vw, 1.8rem);
    text-align: center;
    margin-bottom: 4rem;
    color: #bd0b27;
    text-transform: none;

`

const ProjectsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    justify-content: center;
    padding: 0 2rem;
    margin-bottom: 3rem;

    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

const ProjectCard = styled.div`
    line-height: 2;
    width: 100%;
    height: 300px;
    position: relative;
    border-radius: 5px;
    transition: 0.2s ease;

`

const ProjectInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    font-size: 20px;
    

    @media screen and (max-width: 280px) {
        padding: 0 1rem;
    }
`

const TextWrap = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 250px;
    color: rgba(255, 255, 255, 0.7);
`

const ProjectTitle = styled.div`
    font-size: 1rem;
    margin-left: 0.5rem;
    font-size: 20px;
`

const ProjectImg = styled(Img)`
    height: 100%;
    max-width: 100%;
    position: relative;
    border-radius: 10px;
    filter: brightness(90%);
    transition: 0.4 cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
        filter: brightness(100%);
    }

`

const BiLocation = styled(BiWorld)``
