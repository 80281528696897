import React from "react"
import { FormattedMessage } from 'react-intl'
import { Layout } from '../components/common/Layout'
import Head from '../components/common/Head'
import SEO from "../components/seo"
import Explain from "../components/Explain"
import { ExplainDataOne} from "../data/ExplainData"
import Portfolio from "../components/Portfolio"
import ProjectsSection from "../components/ProjectsSection"
import Scroll from "../components/Scroll"
import "../components/layout.css"
import { projectsObjOne } from "../components/InfoData/Data"
import InfoSection6 from "../components/InfoSections/InfoSection6"

const Projects = () => (
  <Layout>
    <Scroll showBelow={250} />
    <Head title="ProjectsPageTitle" />
    <h1 className="projects"><FormattedMessage id="Projects" /></h1>
    {/* <Portfolio/> */}
    <InfoSection6 {...projectsObjOne} />
    <ProjectsSection heading="Recent Projects" />

  </Layout>
)

export default Projects